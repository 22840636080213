<template>
  <div class="report-container">
    <Form :model="formData" :label-width="120" class="form">
      <Form-item label="检测师：">
        <span>{{ formData.jcs_name }}</span>
      </Form-item>
      <Form-item label="报告类型：">
        <span>{{ types[formData.type] }}</span>
      </Form-item>
      <Form-item label="报告名称：">
        <span>{{ formData.bgzl }}</span>
      </Form-item>
      <Form-item label="报告出具日期：">
        <span>{{ formData.check_date }}</span>
      </Form-item>
      <Form-item label="文件：">
        <img :src="formData.photo_url" alt="pic" class="form-img" v-if="isImage(formData.photo_url)">
        <img src="@/assets/img/placeholder.png" class="form-img" v-else>
      </Form-item>
      <Form-item label="报告描述：">
        <span>{{ formData.bgms }}</span>
      </Form-item>
      <Form-item label="咨询内容：">
        <span>{{ formData.zxnr }}</span>
      </Form-item>
      <Form-item>
        <Button style="margin-left: 10px" @click="$router.back()">返回</Button>
      </Form-item>
    </Form>
  </div>
</template>

<script>
import MemberService from '@/services/memberService';

export default {
	data() {
		return {
			types: ['全部', '检测报告', '训练报告', '问卷报告', '方案报告', '健康总结报告', '其他'],
			formData: {
				type: '',
				bgzl: '',
				check_date: '',
				bgms: '',
				zxnr: '',
				photo_id: '',
			},
		};
	},
	mounted() {
		MemberService.reportDetail({ id: this.$route.params.id }).then((data) => {
			this.formData = data;
			if (this.formData.bgzl) {
				MemberService.getReportType().then((types) => {
					this.formData.bgzl = types[this.formData.type - 1]['child'].filter(
						(item) => item.id === this.formData.bgzl,
					)[0]['name'];
				});
			}
		});
	},
	methods: {
		isImage(path) {
			if (!path) return false;
			return path.match(/\.(png|jpe?g|gif|svg)$/);
		},
	},
};
</script>

<style lang="less" scoped>
.report-container {
  background-color: #fff;
  padding: 15px;
  .form {
    width: 600px;
    margin: 0 auto;
  }
  .form-img {
    width: 300px;
    height: 240px;
    object-fit: contain;
  }
  .span {
    vertical-align: middle;
  }
}
</style>
